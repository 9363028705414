import React from "react";
import {
  useLazyContactUsDataQuery,
  useContactUsDeleteByIdMutation,
} from "../../redux/api/contact-us-data/contact-us-data";
import { BsFillTrashFill } from "react-icons/bs";
import { AiFillEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { BsFillArrowLeftSquareFill } from "react-icons/bs";
import GoBack from "../common/goBack/goBack";
import { useEffect } from "react";
const TeamMemberList = () => {
  // contactUsData({userType:["admin"]});
  const [contactUsData,{
    isLoading,
    isError,
    isSuccess,
    data: ContactUsList,
    refetch: refetchContactUsData,
  }] = useLazyContactUsDataQuery();
  const [
    contactUsDeleteById,
    { isLoading: isDeleting, data: isDeletedResp, status },
  ] = useContactUsDeleteByIdMutation();
  const handleDelete = async (id) => {
    if (!isDeleting) {
      await contactUsDeleteById(id);
      await refetchContactUsData();
      if (isDeletedResp?.hasError === true) {
        return console.log(isDeletedResp);
      }
      console.log(isDeletedResp);
      console.log(status);
      // window.alert(isDeletedResp?.message)
    }
  };
  useEffect(()=>{
    contactUsData() 
  },[])
  return (
    <div style={{ minHeight: "calc(100vh - 70px)" }}>
      <div className="row">
      <div className="col-10 mx-auto text-end mb-3">
        <GoBack link="/admin"/>
        </div>
        <div className="col-10 mx-auto">
          <table className="table text-white">
            <thead>
              <tr className="text-capitalize">
                <th scope="col">#</th>
                <th scope="col">name</th>
                <th scope="col">email</th>
                <th scope="col">message</th>
                <th scope="col">phoneno</th>
                <th scope="col">actions</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={6}>LOADING...</td>
                </tr>
              ) : null}
              {ContactUsList?.result.length > 0 ? (
                ContactUsList.result.map((item, index) => (
                  <tr key={index + 1}>
                    <th className="table-dark" scope="row">
                      {index + 1}
                    </th>
                    <td className="table-dark">{item.name}</td>
                    <td className="table-dark">{item.email}</td>
                    <td className="table-dark">{item.message}</td>
                    <td className="table-dark">{item.phoneno}</td>
                    <td className="table-dark">
                      <Link to={`/admin/contact-us/${item._id}`}>
                        <AiFillEye />
                      </Link>
                      <BsFillTrashFill onClick={() => handleDelete(item._id)} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>
                    <h3>No Data Found</h3>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        
      </div>
    </div>
  );
};

export default TeamMemberList;
