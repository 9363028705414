import { combineReducers, configureStore } from "@reduxjs/toolkit";
//slices
import authSlice from "./features/auth/authSlice";
import authApi from "./api/authApi/authApi";
//redux-persist
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import contactUsApi from "./api/contact-us-data/contact-us-data";
const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};
const persistedReducer = persistReducer(persistConfig, authSlice);
const store = configureStore({
  reducer: combineReducers({
    [authApi.reducerPath]: authApi.reducer,
    [contactUsApi.reducerPath]: contactUsApi.reducer,
    auth: persistedReducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(authApi.middleware),
  devTools: true,
});
export { store };
export const persistor = persistStore(store);
