import React, { useEffect } from "react";
import { useLazyContactUsDataByIdQuery } from "../../redux/api/contact-us-data/contact-us-data";
import { FaCalendarAlt } from "react-icons/fa";
import { RiUserFill } from "react-icons/ri";
import { IoMail } from "react-icons/io5";
import { MdVideoCameraBack } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import SectionHeading from "../../components/SectionHeading";
import Div from "../../components/Div";
import {AiOutlineLoading } from "react-icons/ai"
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import GoBack from "../common/goBack/goBack";
const ContactUsItem = () => {
  const {id}=useParams();

  const [contactUsDataById,{isLoading,data}] = useLazyContactUsDataByIdQuery(id);
  // console.log(ContactUsData)
  useEffect(()=>{
    contactUsDataById(id);
  },[])
  return (
    <Div style={{ minHeight: "calc(100vh - 70px)" }}>
      <Div className="row">
        <Div className="col-12 mx-auto">
          <SectionHeading title="Contact Us - #asda" subtitle="" />
        <GoBack link="/admin/contact-us/"/>
        </Div>
      </Div>
      
      {isLoading?<AiOutlineLoading className="loading"/>:
      <Div className="row">
        <Div className="col-12 mx-auto">
          <Div className="cs-section_heading cs-style1">
            <Div className="cs-separator cs-accent_bg my-4"/>
            {/* date */}
            <h3 className="cs-section_subtitle">
              <FaCalendarAlt /> Date : {moment(data?.result.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
            </h3>
            <Div className="cs-separator cs-accent_bg" />
            {/* name */}
            <h3 className="cs-section_subtitle mt-4">
              <RiUserFill /> Full Name : {data?.result.name}
            </h3>
            <Div className="cs-separator cs-accent_bg" />
            {/* email */}
            <h3 className="cs-section_subtitle mt-4">
              <IoMail /> Email : <a href={`mailto:${data?.result.email}`} target="_blank" rel="noopener noreferrer">
              {data?.result?.email}
              </a></h3>
            <Div className="cs-separator cs-accent_bg" />
            {/* channelurl */}
            <h3 className="cs-section_subtitle mt-4">
              <MdVideoCameraBack /> Channel Url : <a href={`${data?.result.channelurl}`} target="_blank" rel="noopener noreferrer">{data?.result.channelurl}</a>
            </h3>
            <Div className="cs-separator cs-accent_bg" />
            {/* contact no */}
            <h3 className="cs-section_subtitle mt-4">
              <BsTelephoneFill /> Contact No : <a href={`tel:${data?.result.phoneno}`} target="_blank" rel="noopener noreferrer">{data?.result.phoneno}</a>
            </h3>
            <Div className="cs-separator cs-accent_bg" />
            {/* message */}
            <h3 className="cs-section_subtitle mt-4">Message :</h3>
            <Div className="cs-separator cs-accent_bg mb-4" />
            <p className="cs-m0 col-lg-8">
              {data?.result.message}
            </p>
            <Div className="cs-height_45 cs-height_lg_30" />
          </Div>
        </Div>
      </Div>}
    </Div>
  );
};

export default ContactUsItem;
