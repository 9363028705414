import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState: null,
    reducers: {
        login:  (state, action) => {
            if(action.payload.status===200){
                state.user =action.payload.result;
            }
        },
        logOut: (state) => {
            return state = null;
           
        }
    },
})
export const { login, logOut } = authSlice.actions

export default authSlice.reducer


