import React from "react";
import Div from "../../components/Div";
import Spacing from "../../components/Spacing";
import Card from "../../components/Card";
import TeamImage from "../common/assets/images/dashboard-team.jpg"
const AdminHome = () => {
  return (
    <div style={{ minHeight: "calc(100vh - 70px)" }}>
      <div className="row">
        <div className="col-10 mx-auto">
          <Div className="col-lg-3 col-xxl-2 col-sm-6 d-flex">
            <Card
              title="Contact Us"
              link="/admin/contact-us"
              src="/images/service_1.jpeg"
              alt="Service"
            />
            
            <Card
              title="Team Members"
              link="/admin/team-members"
              src={TeamImage}
              alt="Team Member"
            />
          </Div>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
