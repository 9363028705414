import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl:"http://localhost:5000/api/user"
    }),
    endpoints: builder => ({
        authlogin: builder.mutation({
            query: (payload) => ({
                url: '/login',
                method: 'POST',
                body: payload,
              }),
        }),
        authregister:builder.mutation({
            query:(payload)=>({
                url:'/register',
                method:"POST",
                body: payload
            })
        })
    })
})
export default authApi
export const { useAuthloginMutation, useAuthregisterMutation } = authApi;