import React, { useEffect } from "react";
// axios instance
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
// import { userLogin } from '../../features/auth/authActions'
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/features/auth/authSlice";
import { useAuthloginMutation } from "../../redux/api/authApi/authApi";
import {AiOutlineLoading} from "react-icons/ai"
const Login = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [authlogin, { isLoading:isLoginLoading }] = useAuthloginMutation();

  const FormSchema = yup.object().shape({
    email: yup.string().email().required("Email is required."),
    password: yup.string().required("Password is required."),
  });
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FormSchema),
  });

  // submiting form field
  const onSubmit = async (frmdata) => {
    const { data } = await authlogin(frmdata);
    if (data.status === 200) {
      dispatch(login(data));
      navigate("/admin");
    }
  };
  const admin = useSelector((state) => state.auth.user);
  const Screen = () => {
    return (
      <div className="container-fluid">
        <div className="row">
          <form
            className="col-8 col-md-6 mx-auto min-vh-100 d-flex justify-content-center align-items-center flex-column"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form-group w-100">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter email"
                {...register("email", { required: true })}
              />
              <small className="form-text text-muted">
                {errors?.email?.message}
              </small>
            </div>
            <div className="form-group w-100 mt-3">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Password"
                {...register("password", { required: true })}
              />
              <small className="form-text text-muted">
                {errors?.password?.message}
              </small>
            </div>
            {isLoginLoading?<AiOutlineLoading className="loading"/>:<button type="submit" className="btn btn-primary mt-3">
              Submit
            </button>}
          </form>
        </div>
      </div>
    );
  };
  if (admin !== undefined) {
    return navigate("/admin");
  }
  return <Screen />;
};

export default Login;
