import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import ContactPage from "./components/Pages/ContactPage";
import ErrorPage from "./components/Pages/ErrorPage";
import DigitalAgencyHome from "./components/Pages/DigitalAgencyHome";
import TeamDetails from "./components/Pages/TeamDetails";
import TeamPage from "./components/Pages/TeamPage";
import ProtectedRoute from "./helper/ProtectedRoute";
import Login from "./admin/login/page";
import AdminHome from "./admin/home/page";
import { useSelector } from "react-redux";
import ContactUsList from "./admin/contact-us-data/contact-us-list";
import ContactUsItem from "./admin/contact-us-data/contact-us-item";
import TeamMemberList from "./admin/team-members/team-member-list";
import AboutPage from "./components/Pages/AboutPage";
function App() {
  const auth = useSelector((state) => state.auth.user);
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<DigitalAgencyHome />} />
          {/* <Route path="about" element={<AboutPage />} /> */}
          {/* <Route path="contact" element={<ContactPage />} /> */}
          <Route path="team" element={<TeamPage />} />
          <Route path="team/:teamDetails" element={<TeamDetails />} />
        </Route>

        <Route
          path="/login"
          element={auth === undefined ? <Login /> : <Navigate to="/admin" />}
        />
        <Route exact path="/admin" element={<ProtectedRoute />}>
          <Route index element={<AdminHome />} />
          <Route path="contact-us" element={<ContactUsList />} />
          <Route path="contact-us/:id" element={<ContactUsItem />} />
          <Route path="team-members" element={<TeamMemberList/>} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
