import React from 'react'
import Div from '../../../components/Div';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Spacing from '../../../components/Spacing';
const ContactUsListForm = () => {
  const FormSchema = yup.object().shape({
    name: yup.string(),
    email: yup.string().email("Enter a valid email address"),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FormSchema),
  });
  const onSubmit = (data) => {
    console.log(data)
  };
  return (
    <form  onSubmit={handleSubmit(onSubmit)}
    autoComplete="false"
  autoSave="false" 
  className="row">
      <Div className="col-sm-4">
        <label className="cs-primary_color">Full Name</label>
        <input type="text" className="cs-form_field" {...register("name", { required: true })} />
        {errors?.name && (
        <p className="text-red-500 mt-2 font-mono">
          <>{errors?.name?.message}</>
        </p>
      )}
        <Spacing lg="20" md="20" />
      </Div>
      <Div className="col-sm-4">
        <label className="cs-primary_color">Email</label>
        <input type="text" className="cs-form_field" {...register("email")} />
        {errors?.email && (
        <p className="text-red-500 mt-2 font-mono">
          <>{errors?.email?.message}</>
        </p>
      )}
        <Spacing lg="20" md="20" />
      </Div>
     
     
      <Div className="col-sm-3 d-flex justify-content-center align-items-center">
        {/* {loading?null:<button className="cs-btn cs-style1">
          <span>Send Message</span>
          <Icon icon="bi:arrow-right" />
        </button>} */}
        <button className="cs-btn cs-style1">
          <span>Submit</span>
        </button>
      </Div>
    </form>  
  )
}

export default ContactUsListForm