import React from 'react'
import { BsFillArrowLeftSquareFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const GoBack = (props) => {
  return (
    <Link to={props.link}><BsFillArrowLeftSquareFill className="me-1"/>Go Back</Link>
  )
}

export default GoBack