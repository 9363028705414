import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "./navbar.css"
import { useDispatch } from 'react-redux'
import { logOut } from '../../../redux/features/auth/authSlice'
import Logo from '../assets/images/Logo liven.png'
const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout=()=>{
    dispatch(logOut());
    navigate("/login");
  }
  return (
<nav className="navbar navbar-dark admin-navbar">
  <Link className="navbar-brand fw-bold" to="/admin">
    <img src={Logo} width="30" height="30" className="me-2" alt=""/>
    LIVEN ESPORTS
  </Link>
  <div className="dropdown-container">
    Profile
    <div className='flex-column nav-bar-dropdown'>
      <p>Settings</p>
      <p onClick={handleLogout}>Logout</p>
    </div>
  </div>
</nav>
  )
}

export default NavBar