// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// const contactUsApi = createApi({
//     baseQuery: fetchBaseQuery({
//         baseUrl:"http://localhost:5000/api/admin/contactus-data",
//         prepareHeaders:(headers, { getState })=>{
//             const token = getState().authSlice.user.token
//             if (token) {
//              // include token in req header
//               headers.set('token', `${token}`)  
//               return headers
//             }
//         }
//     }),
//     endpoints: builder => ({
//         contactUsData: builder.query({
//             query: (payload) => ({
//                 url: '/contactus-data',
//                 body:payload
//               }),
//         }),
//     })
// })
// export default contactUsApi
// export const { useContactUsDataMutation } = contactUsApi;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const contactUsApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl:"http://localhost:5000/api/admin",
        prepareHeaders:(headers, { getState })=>{
                         const token = getState().auth.user.token
                         if (token) {
                           headers.set('token', `${token}`)  
                        }
                        return headers
                    }
    }),
    endpoints: builder => ({
        contactUsData: builder.query({
            query: (filter) => ({
                url: `/contactus${filter? "?"+new URLSearchParams(filter).toString():''}`,
                method: 'GET',
              }),
              providesTags: ['contactUsData'],
        }),
        contactUsDataById: builder.query({
            query: (payload) => ({
                url: `/contactus/${payload}`,
                method: 'GET',
              }),
              providesTags: ['contactUsData'],
        }),
        contactUsDeleteById:builder.mutation({
            query:(payload)=>({
                url:`/contactus/${payload}`,
                method: 'Delete'
            }),
            providesTags: ['contactUsData'],
            
        }),
    })
})
export default contactUsApi
export const { useLazyContactUsDataQuery, useLazyContactUsDataByIdQuery, useContactUsDeleteByIdMutation } = contactUsApi;