import React, { useEffect, useState } from 'react';
import Hero4 from '../Hero/Hero4';
import { pageTitle } from '../../helper';
import Div from '../Div';
import FunFact2 from '../FunFact/FunFact2';
import Spacing from '../Spacing';
import SectionHeading, { SectionHeadingAlt } from '../SectionHeading';
import MovingText2 from '../MovingText/MovingText2';
import VideoModal from '../VideoModal';
import Portfolio from '../Portfolio';
import { Icon } from '@iconify/react';
// import TimelineSlider from '../Slider/TimelineSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import Cta from '../Cta';
import LogoList from '../LogoList';
// import PostList from '../Post/PostList';
import "../Hero/hero.scss"
import "../FunFact/funfact.scss"
// import { Link } from 'react-router-dom';
export default function DigitalAgencyHome() {
  // const [itemShow, setItemShow] = useState(6);
  pageTitle('Liven Esports | ');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroSocialLinks = [
    {
      name: 'LinkedIn',
      links: 'https://www.linkedin.com/company/livenesports/',
    },
    {
      name: 'Instagram',
      links: 'https://www.instagram.com/livenesports/',
    },
  ];
  const funfaceData = [
    {
      title: 'Creators',
      factNumber: '5000',
    },
    {
      title: 'Impressions',
      factNumber: '5B',
    },
    {
      title: 'Retention Rate',
      factNumber: '2.5M',
    },
    {
      title: 'Brands',
      factNumber: '150',
    },
  ];
  const serviceData1 = [
    {
      title: 'Branding',
      href: '/',
    },
    {
      title: 'Marketing',
      href: '/',
    },
    {
      title: 'Technical Support',
      href: '/',
    },
  ];
  const serviceData2 = [
    {
      title: 'Creative Support',
      href: '/',
    },
    {
      title: 'Talent Management',
      href: '/',
    },
    {
      title: 'Brand Value',
      href: '/',
    },
    {
      title: 'User Retention',
      href: '/',
    },
  ];
 /*
  const portfolioData = [
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_11.jpeg',
      category: 'ui_ux_design',
    },
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_12.jpeg',
      category: 'logo_design',
    },
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_13.jpeg',
      category: 'web_design',
    },
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_14.jpeg',
      category: 'mobile_apps',
    },
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_15.jpeg',
      category: 'ui_ux_design',
    },
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_16.jpeg',
      category: 'web_design',
    },
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_11.jpeg',
      category: 'ui_ux_design',
    },
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_12.jpeg',
      category: 'logo_design',
    },
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_13.jpeg',
      category: 'web_design',
    },
  ];
  const teamMembers=[ {
    title: 'Ayush Choudhary',
    subtitle: 'CEO',
    href: '/portfolio/portfolio-details',
    src: '/images/team-member-images/member_1.jpeg',
    category: 'Member Detail',
  }
  // , {
  //   title: 'Pratyakash Chaudhary',
  //   subtitle: 'COO',
  //   href: '/portfolio/portfolio-details',
  //   src: '/images/team-member-images/member_2.jpeg',
  //   category: 'Member Detail',
  // }, {
  //   title: 'Saksham Katoch',
  //   subtitle: 'Chief Finance Officer',
  //   href: '/portfolio/portfolio-details',
  //   src: '/images/team-member-images/member_3.jpg',
  //   category: 'Member Detail',
  // },  
]
 */
  return (
    <>
      {/* Start Hero Section */}
      <Hero4
        title="We Are <span>L</span>iven <br /><span>E</span>sports"
        subtitle="Where Indian Creativity Meets Global Opportunity. <br /> Welcome to Our Content Creator Program"
        scrollDownId="service"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        phoneNumber="+91 82194 87097"
        email="contact@livenesports.com"
      />
      {/* End Hero Section */}

      {/* Start Funfact Section */}
      <Div className="container" id="service">
        <FunFact2
          data={funfaceData}
          variant="cs-type1"
          bgUrl="/images/funfact_shape_bg.svg"
        />
      </Div>
      {/* End Funfact Section */}

      {/* Start Services Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="What we provide"
          subtitle="Services"
          variant="cs-style1 text-center"
        />
        <Spacing lg="65" md="45" />
      </Div>
      {/* End Services Section */}

      {/* Start Moving Text Section */}
      <MovingText2 data={serviceData1} />
      <Spacing lg="20" md="10" />
      <MovingText2 reverseDirection data={serviceData2} />
      {/* End Moving Text Section */}

      {/* Start About Section */}
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Spacing lg="150" md="80" />
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-5 col-xl-4">
              <SectionHeadingAlt
                title="Why Choose Us"
                subtitle="The organisation's primary
                goal is to assist aspiring gaming content creators to monetize their
                content through mentorship and brand integration, as well as to provide
                platforms and publishers with the tools to reach and retain audiences.
                Liven Esports will be Heading India's Largest Network Of Gaming
                Influencers Reaching 10 billion+ Viewership with 5000+creators under
                them and increasing exponentially on a daily basis and aggregating 150
                Million - 300 Million impressions per day, and growing exponentially
                every day."
                btnText="Learn More"
                btnLink="https://forms.gle/zqbZU16SyM7WcZVv7"
                type="custom"
              />
              <Spacing lg="45" md="45" />
            </Div>
            <Div className="col-lg-7 offset-xl-1">
              <Div className="cs-half_screen">
                <VideoModal
                  videoSrc="https://www.youtube.com/watch?v=HbLDKhwNlcE"
                  bgUrl="/images/video_bg.jpeg"
                  variant="cs-style1 cs-size1"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End About Section */}

      {/* Start Projects Section */}
      <Spacing lg="90" md="45" />
      {/* <Spacing lg="145" md="80" />
      <Div className="container mb-5">
        <SectionHeading
          title="Our Best Creators"
          subtitle="Look at"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          {portfolioData.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 0 || index === 3 || index === 4
                  ? 'col-lg-8'
                  : 'col-lg-4'
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ''
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div> */}
      {/* End Projects Section */}
      {/* Start Projects Section */}
      {/* <Spacing lg="145" md="80" /> */}
      {/* <Div className="container mb-5">
        <SectionHeading
          title="Our Team"
          subtitle="Look at"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          {teamMembers.map((item, index) => (
            <Div
              className={`col-lg-4`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>
         <Div className="text-center">
              <Spacing lg="65" md="40" />
              <Link to="/team"><span
                className="cs-text_btn"
              >
                <span>Show More</span>
                <Icon icon="bi:arrow-right" />
              </span>
              </Link>
        </Div> 
      </Div> */}
      {/* End Projects Section */}

      {/* Start Awards Section */}
      {/* <Spacing lg="140" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-4">
            <SectionHeading
              title="We get multiple awards"
              subtitle="Our Awards"
              variant="cs-style1"
            />
            <Spacing lg="90" md="45" />
          </Div>
          <Div className="col-xl-7 offset-xl-1">
            <TimelineSlider />
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" /> */}
      {/* End Awards Section */}

      {/* Start Testimonial Section */}
      {/* <TestimonialSlider /> */}
      {/* End Testimonial Section */}

      {/* Start Blog Section */}
      {/* <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Spacing lg="145" md="80" />
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-5 col-xl-4">
              <SectionHeading
                title="Explore recent publication"
                subtitle="Our Blog"
                btnText="View More Blog"
                btnLink="/blog"
              />
              <Spacing lg="45" md="45" />
            </Div>
            <Div className="col-lg-7 offset-xl-1">
              <PostList />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" /> */}
      {/* End Blog Section */}

      {/* Start LogoList Section */}
      {/* <Div className="container mt-5"> */}
     {/* 
      <Div className="container">
        <SectionHeading
          title="Our reputed partner"
          subtitle="Top Clients"
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <LogoList />
      </Div>
      <Spacing lg="70" md="45" />
     */}
      {/* <Spacing lg="150" md="80" /> */}
      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container mb-5">
        <Cta
          title="Let's collaborate to <br />create <i>something</i> amazing!"
          btnText="Join Us Now"
          bgSrc="/images/cta_bg.jpeg"
          btnLink="https://forms.gle/zqbZU16SyM7WcZVv7"
          type={"custom"}
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
