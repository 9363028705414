import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import NavBar from "../admin/common/navbar/navbar";
const ProtectedRoute = () => {
  const auth = useSelector((state) => {
  return state.auth
  });
  return auth?.user ===undefined?
    <Navigate to="/login" />:
  (
      <>
        <div className="container-fluid">
          <NavBar/>
          <div className="row pt-3">
            <div className="col-12">
              <Outlet />
            </div>
          </div>
        </div>
      </>
    );
}

export default ProtectedRoute;
