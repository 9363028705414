import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Team from '../Team';

export default function TeamPage() {
  pageTitle('Team');
  const teamData = [
    {
      memberImage: '/images/team-member-images/member_1.png',
      memberName: 'Ayush Choudhary',
      memberDesignation: 'CEO',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/bornruthless/',
        instagram:'https://www.instagram.com/_born_ruthless_',
        // twitter: '/',
        // youtube: '/',
        facebook: 'https://www.facebook.com/ayushsoft12',
      },
    },
    {
      memberImage: '/images/team-member-images/member_3.png',
      memberName: 'Hazrat',
      memberDesignation: 'Manager',
      memberSocial: {
        instagram:"https://www.instagram.com/hazratummar"
      },
    },
    {
      memberImage: '/images/team-member-images/member_2.png',
      memberName: 'Vinayak Soni',
      memberDesignation: 'Full Stack Developer',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/vinayaksoni98/',
        instagram:'https://www.instagram.com/vinayak.soni98/',
        twitter: 'https://twitter.com/sonivinayak1998',
      },
    },

  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading
        title="Our Team"
        bgSrc="images/team_hero_bg.jpeg"
        pageLinkText="Team"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Meet our awesome <br/>team members"
          subtitle="Our Team"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          {teamData.map((item, index) => (
            <Div key={index} className="col-lg-3 col-sm-6">
              <Team
                memberImage={item.memberImage}
                memberName={item.memberName}
                memberDesignation={item.memberDesignation}
                memberSocial={item.memberSocial}
              />
              <Spacing lg="80" md="30" />
            </Div>
          ))}
        </Div>
        <Spacing lg="70" md="50" />
        <Div className="container">
          <Cta
            title="Let's collaborate to <br />create <i>something</i> amazing!"
            btnText="Apply For Meeting"
            btnLink="https://forms.gle/zqbZU16SyM7WcZVv7"
            bgSrc="/images/cta_bg.jpeg"
            type={"custom"}
          />
        </Div>
      </Div>
    </>
  );
}
