import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import Div from '../Div';
import VerticalLinks from '../VerticalLinks';

export default function Hero4({
  title,
  subtitle,
  scrollDownId,
  socialLinksHeading,
  heroSocialLinks,
  phoneNumber,
  email,
}) {
  const [gap, setGap] = useState(20); // Default gap

    useEffect(() => {
        // Media query logic to adjust gap
        const updateGap = () => {
            if (window.innerWidth < 768) {
                setGap(90); // Smaller gap for smaller screens
            } else {
                setGap(120); // Larger gap for larger screens
            }
        };

        // Initial check
        updateGap();

        // Update gap on resize
        window.addEventListener("resize", updateGap);

        return () => {
            window.removeEventListener("resize", updateGap);
        };
    }, []); // Empty dependency array ensures this runs once and on resize

    const scrollToElementWithGap = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - gap;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth", // Smooth scrolling
            });
        } else {
            console.error(`Element with ID '${elementId}' not found.`);
        }
    };
  return (
    <Div
      className="cs-hero cs-style1 cs-type1 text-center cs-fixed_bg"
      style={{ backgroundImage: 'url(./images/hero_bg_2.jpeg)' }}
    >
      <Div className="container">
        <Div className="cs-hero_text">
          <h1 className="cs-hero_title">{parse(title)}</h1>
          <Div className="cs-hero_info justify-content-center">
            <Div className="cs-hero_subtitle">{parse(subtitle)}</Div>
          </Div>
        </Div>
      </Div>
      <VerticalLinks data={heroSocialLinks} title={socialLinksHeading} />
      {(phoneNumber || email) && (
        <Div className="cs-hero_social_wrap cs-left_side cs-primary_font cs-primary_color">
          <ul className="cs-hero_social_links">
            {email && (
              <li>
                <span>{email}</span>
              </li>
            )}
            {phoneNumber && (
              <li>
                <span>{phoneNumber}</span>
              </li>
            )}
          </ul>
        </Div>
      )}
      <span onClick={()=>scrollToElementWithGap(scrollDownId)} className="cs-down_btn">
        .
      </span>
    </Div>
  );
}
